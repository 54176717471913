import React, {useEffect, useState} from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import {Basic, Facebook, Twitter} from '../components/SEO'
import {metadata} from '../../config'
import loadable from "@loadable/component";
import AuthorDetails from "./authorDetails";
import CategoryTitle from "../components/helpers/categoryTitle";
import {List, WindowScroller} from "react-virtualized";
import ArticlePreview from "./articlePreview";
import useWindowDimensions from "../hooks/useWindowDimensions";

const PopularPosts = loadable(() => import("../components/popularPosts"));

// The pages created in gatsby-node.js will use this component("template" by gatsby convention)
// The data is recieved by the graphQL query from the bottom of this component
const AuthorPage = props => {

    const profile = props.data.author.bio

    // SEO - title (Page, twitter, facebook)
    const title = props.data.author.name

    // SEO - url (Page, twitter, facebook)
    const url = `${metadata.url}/authors/${props.data.author.slug}`

    const seo = {
        title: `${metadata.defaultTitle} | ${title}`,
        description: profile,
        image: props.data.author.profilePhoto
    }

    const [rowHeight, setRowHeight] = useState(305);
    const [articleHeight, setArticleHeight] = useState(290);
    const {width} = useWindowDimensions();

    const calculateHeights = () => {
        if (width <= 375) {
            setRowHeight(294);
            setArticleHeight(279)
        } else if (width <= 560) {
            setRowHeight(267);
            setArticleHeight(252)
        }
    }

    useEffect(() => {
        calculateHeights();
    }, []);

    useEffect(() => {
        calculateHeights();
    }, [width]);

    function rowRenderer({
                             index, // Index of row
                             isScrolling, // The List is currently being scrolled
                             isVisible, // This row is visible within the List (eg it is not an overscanned row)
                             key, // Unique key within array of rendered rows
                             parent, // Reference to the parent List (instance)
                             style, // Style object to be applied to row (to position it);
                             // This must be passed through to the rendered row element.
                         }) {

        return (
            <div key={key} style={style}>
                {isScrolling && !isVisible ?
                    <div style={{minHeight: articleHeight, width: "100%"}} className={"article-placeholder"}/> :
                    <ArticlePreview style={{minHeight: articleHeight}} data={props.data.allDatoCmsArticle.nodes[index]}
                                    className={"border-b-default border-gray-400"}
                                    imageClass="mb-0 w-1/3 lg:mr-4 float-right ml-2 lg:ml-0 lg:float-left mt-8 md:mt-0"
                                    headingClass="font-serif leading-snug mb-1 md:mb-4 text-lg"
                                    textClass={"line-clamp l-c-5 mb-2"}
                                    publishedAt={false}
                                    imageMeta={{w: 320, ar: '4:3', fit: 'crop'}}
                                    noReadMore={true}
                                    publishedAtFormat={'mmmm d, yyyy'}
                                    noAuthor={true}/>}
            </div>
        );
    }

    return (
        <Layout>
            {/* SEO data for the html <head></head> */}
            <Basic seo={seo}/>
            <Twitter seo={seo} url={url}/>
            <Facebook seo={seo} url={url}/>

            <main className="wrapper" role="main" data-datocms-noindex>
                <div className={"w-full flex"}>
                    <article className={"flex-grow-3"}>
                        <AuthorDetails author={props.data.author}/>
                        <hr className={"mb-4"}/>
                        <CategoryTitle title="Latest Articles"/>
                        <div>
                            <WindowScroller>
                                {({height, isScrolling, onChildScroll, scrollTop, width}) => (
                                    <List
                                        autoHeight
                                        height={height}
                                        isScrolling={isScrolling}
                                        onScroll={onChildScroll}
                                        rowCount={props.data.allDatoCmsArticle.totalCount}
                                        rowRenderer={rowRenderer}
                                        scrollTop={scrollTop}
                                        rowHeight={rowHeight}
                                        width={width}
                                        style={{width: "unset !important"}}
                                    />
                                )}
                            </WindowScroller>
                        </div>
                    </article>
                    <PopularPosts className="flex-1 ml-4"/>
                </div>
            </main>
        </Layout>
    )
}

// in gatsby-node.js we gave a context "slug"
// we can use that to filter the matching publication and slug now
export const query = graphql`
    query AuthorQuery($slug: String!) {
        author: datoCmsAuthor(slug: {eq: $slug}) {
            bio
            linkedin
            email
            twitter
            profilePhoto {
                url,
                width,
                height
            }
            name
            slug
        }
        allDatoCmsArticle (filter: {author: {slug: {eq: $slug}}, website: {elemMatch: {name: {eq: "pt"}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                heading
                excerpt
                slug
                category {
                    title
                    slug
                    treeParent {
                        slug
                    }
                    position
                    website {
                        name
                    }
                }
                cover {
                    url
                    gatsbyImageData(
                        layout: FULL_WIDTH,
                        placeholder: BLURRED,
                        forceBlurhash: false,
                        imgixParams: { auto: "compress", fit: "crop", w: "450", q: 40, ar: "3:2" }
                    )
                    width
                    height
                }
                publishedAt
                content
            }
            totalCount
        }
    }
`
export default AuthorPage
